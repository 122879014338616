import { useToast } from 'vue-toastification';
import type { BrandPageResponse, BrandsListResponse } from '~/types/NewTypes/Brand';
import type { NewReviewBody } from '~/types/Catalog';
import type { CatalogResponse, OfferFull, OfferMedium, SaleResponse } from '~/types/NewTypes/CatalogNew';
import type { CategoriesListResponse } from '~/types/NewTypes/Category';
import type { ReviewListResponse } from '~/types/NewTypes/Review';
import type { SearchPageResponse } from '~/types/NewTypes/Search';
import type { FavoriteItemsListResponse } from '~/types/NewTypes/Favorite';

export interface CatalogStore {
  currentPageType: string | null;
  catalogPageIsLoading: boolean;

  productPageFoldableBlocks: {
    description: boolean;
    characteristics: boolean;
    videos: boolean;
    reviews: boolean;
  };

  selectedOffer: OfferFull;

  favoriteOffersIds: number[];
}

export const useCatalogStore = createCatalogStore({
  currentPageType: null,
  catalogPageIsLoading: false,

  productPageFoldableBlocks: {
    description: true,
    characteristics: true,
    videos: true,
    reviews: true,
  },

  selectedOffer: null as unknown as OfferFull,

  favoriteOffersIds: [] as number[],
});

export function createCatalogStore(initialState: CatalogStore) {
  return defineStore('catalog', {
    state: () => initialState,

    actions: {
      async getCategories() {
        try {
          const { data } = await useCustomFetch<CategoriesListResponse>('/catalog/categories', {}, '2');

          if (data.value) {
            return data.value.data.categories;
          } else {
            return [];
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return [];
        }
      },

      async getBrands() {
        try {
          const { data } = await useCustomFetch<BrandsListResponse>('/catalog/brands', {}, '2');

          if (data.value) {
            return data.value.data.brands;
          } else {
            return [];
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return [];
        }
      },

      async getBrandBySlug() {
        try {
          const { query, params } = useRoute();

          const { data } = await useCustomFetch<BrandPageResponse>(`/catalog/brands/${params.name}`, { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getSale() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const { data } = await useCustomFetch<SaleResponse>('/catalog/sales', { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getFavorites() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const { data } = await useCustomFetch<FavoriteItemsListResponse>('/favorite', { query }, '2');

          if (data.value) {
            this.favoriteOffersIds = data.value.data.offers.map((offer: OfferMedium) => offer.id);

            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async toggleFavorite(offerId: number) {
        try {
          const { data } = await useCustomFetch<{ data: []; success: boolean }>('/favorite', {
            method: 'POST',
            body: { id: offerId, model: 'offer' },
          });

          if (data?.value?.success) {
            if (this.favoriteOffersIds.length && this.favoriteOffersIds.includes(offerId)) {
              this.favoriteOffersIds = this.favoriteOffersIds.filter((id: number) => id !== offerId);
            } else {
              this.favoriteOffersIds.push(offerId);
            }
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async getSearchResults() {
        try {
          this.catalogPageIsLoading = true;
          const { query } = useRoute();

          const { data } = await useCustomFetch<SearchPageResponse>('/search', { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getCatalogData() {
        try {
          this.catalogPageIsLoading = true;
          // this.currentPageType = null;

          const { query, params } = useRoute();
          let slug = params.slug;

          if (slug && Array.isArray(slug)) {
            slug = slug.join('/');
          } else if (!slug) {
            return null;
          }

          const { data } = await useCustomFetch<CatalogResponse>(`/catalog/show/${slug}`, { query }, '2');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        } finally {
          this.catalogPageIsLoading = false;
        }
      },

      async getReviews(productId: number, page?: number) {
        try {
          const { data } = await useCustomFetch<ReviewListResponse>(
            `/testimonials/${productId}`,
            { query: { page } },
            '2',
          );

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async sendReview(review: NewReviewBody) {
        try {
          const form = new FormData();

          for (const [key, value] of Object.entries(review)) {
            if (key === 'images') {
              for (let i = 0; i < review.images.length; i++) {
                form.append('images[]', review.images[i]);
              }
            } else {
              form.append(key, value);
            }
          }

          const { data, error } = await useCustomFetch<{ data: any[]; success: boolean }>('/testimonials', {
            method: 'POST',
            body: form,
          });

          const toast = useToast();

          if (data.value?.success) {
            toast.success('Отзыв успешно отправлен! Он появится на странице товара после модерации.');
            return true;
          }
          if (error.value) {
            toast.error(error.value.data.message);
            return false;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },
    },
  });
}
